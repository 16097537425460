import React from 'react';
import {
  BrowserRouter as Router,
  Route,Switch
} from 'react-router-dom'
import './App.scss';
import Loadable from 'react-loadable';
import CookieConsent from "react-cookie-consent";
import CacheBuster from './CacheBuster';
const loading = () => <div className="" >Loading...</div>;
const AgilityThinkingLayout = Loadable({
  loader: () => import('./containers/DefaultLayout/AgilityThinkingLayout'),
  loading
});
const AgilityThinking = React.lazy(() => import('./views/AgilityThinking/AgilityThinking'));
const AboutUs = React.lazy(() => import('./views/AboutUs/AboutUs'));
const Video = React.lazy(() => import('./views/Videos/Video'));
const SolitaryEvent = React.lazy(() => import('./views/Events/SolitaryEvent'));
const SolitaryGroup = React.lazy(() => import('./views/Events/SolitaryGroup'));
const Blog = React.lazy(() => import('./views/Blogs/Blog'));
// const Public = () => <h3>Public</h3>

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)
export default function AuthExample () {
  return (
    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <Router>
                <div>
                    <CookieConsent>
                        This website uses cookies
                        We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. Visit our <a className="blue-color" href='/terms-and-conditions/'> terms and conditions </a> for more information.
                    </CookieConsent>
                    <Switch>
                        <AppRoute path="/about-us" layout={ AgilityThinkingLayout } component={ AboutUs } />
                        <AppRoute path="/events/:name" layout={ AgilityThinkingLayout } component={ SolitaryEvent } />
                        <AppRoute path="/groups/:name" layout={ AgilityThinkingLayout } component={ SolitaryGroup } />
                        <AppRoute path="/videos/:video_name" layout={ AgilityThinkingLayout } component={ Video } />
                        <AppRoute path="/blogs/:title" layout={ AgilityThinkingLayout } component={ Blog } />
                        <AppRoute path="/" layout={ AgilityThinkingLayout } component={ AgilityThinking } />
                    </Switch>
                </div>
            </Router>
          );
        }}
      </CacheBuster>
    )
}
